module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Men's Health Clinic | Leaders in ED, PE & Chronic Pain Treatment","description":"MHC provides customised treatment solutions for premature ejaculation, erectile dysfunction and chronic pain. Talk to one of our licensed men's health doctors today!","openGraph":{"type":"website","url":"https://menshealthclinic.com/","title":"Men's Health Clinic | Leaders in ED, PE & Chronic Pain Treatment","description":"MHC provides customised treatment solutions for premature ejaculation, erectile dysfunction and chronic pain. Talk to one of our licensed men's health doctors today!"},"twitter":{"cardType":"summary_large_image","url":"https://menshealthclinic.com/"},"metaTags":[{"name":"keywords","content":"australia, canada, united kingdom, new zealand, mens, men's, health, clinic, mens health clinic, men's health clinic, mhc, the mens health clinic, the men's health clinic, the mhc, erectile dysfunction, premature ejaculation, chronic pain, ed, pe, ed treatment, pe treatment, erectile dysfunction treatment, premature ejaculation treatment, chronic pain treatment, online assessment, australian doctors, canadian doctors, united kingdom doctors, new zealand doctors, sex clinic, sexual treatment, sex therapy"},{"name":"robots","content":"index, follow"},{"name":"language","content":"English"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#000000"},{"name":"author","content":"Men's Health Clinic"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://menshealthclinic.com","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Men's Health Clinic","short_name":"MHC","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/favicon/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12d3a5769ff3e8fc2d9af2832aa108ff"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PDHP6M7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
